import { FC, memo, useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';

import { ArrowIcon } from '../../../../Icons';
import { DropdownDataType, DropdownType } from '../../../types';
import { useScrollIntoView, useOutsideClick } from '../../../../../hooks';

import { selectStyles } from '../style';
import { AreaSelectedValues } from './AreaSelectedValues';
import { DropdownContainer } from './DropdownContainer';
import { DropdownIndicator } from './DropdownIndicator';

export type DropdownProps = DropdownType & {
  onChange?: (value: any) => void;
  disabled?: boolean;
  error?: string;
};

export const Dropdown: FC<DropdownProps> = memo(
  ({
    label = '',
    selectedValue,
    options,
    isMulti = false,
    placeholder = '',
    size = 'large',
    isSearchable = true,
    onChange,
    className = '',
    disabled = false,
    error = '',
  }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [value, setValue] = useState<any>(selectedValue);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useScrollIntoView(wrapperRef, !!error);

    useEffect(() => {
      if (selectedValue?.value) {
        setValue(selectedValue);
      }
    }, [selectedValue, selectedValue?.value]);

    const onSelectChange = (v: DropdownDataType) => {
      if (isMulti) {
        const isInclude = value?.some((value: DropdownDataType) => value.value === v?.value);
        if (isInclude) return removeItem(v);
        return setValue((prev = []) => {
          onChange && onChange([...prev, v]);
          return [...prev, v];
        });
      } else setValue(v);
      // eslint-disable-next-line
      onChange && onChange(v!);
      closeModal();
    };

    const removeItem = (v: DropdownDataType) => {
      const newValue = value?.filter((i: DropdownDataType) => i.value !== v.value);
      onChange && onChange(newValue);
      setValue(newValue);
    };

    useOutsideClick(wrapperRef, () => {
      setIsOpen(false);
    });

    const closeModal = () => setIsOpen(false);
    const openModal = (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      setIsOpen(true);
    };

    const Option = (props: any) => {
      return (
        <div>
          <components.Option {...props}>
            <div
              onClick={(event) => {
                event.stopPropagation();
                onSelectChange({ label: props.label, value: props.value });
              }}
              className='flex items-center gap-0'
            >
              <input
                type='checkbox'
                checked={props.isSelected}
                onChange={() => null}
                className="appearance-none w-4 min-w-[16px] h-4 border border-grey rounded cursor-pointer
        checked:bg-[url('/src/assets/svg/checked.svg')] checked:border-none transition"
              />
              <label className='w-full ml-2'>{props.label}</label>
            </div>
          </components.Option>
        </div>
      );
    };

    const isLarge = size === 'large';
    return (
      <div ref={wrapperRef} className={className}>
        {(label || isMulti) && (
          <div className='flex items-center justify-between mb-1'>
            {label && <span className={`${isLarge ? 'input-label' : 'font-medium text-xs'}`}>{label}</span>}
            {((isMulti && !!value?.length) || (value?.value && isOpen)) && (
              <span
                className='text-sm/4 text-[#E9344B] cursor-pointer'
                onClick={() => {
                  setValue(isMulti ? [] : null);
                  onChange && onChange(isMulti ? [] : null);
                }}
              >
                Clear
              </span>
            )}
          </div>
        )}
        <DropdownContainer
          isOpen={isOpen}
          onClose={closeModal}
          isMulti={isMulti}
          value={value}
          setValue={setValue}
          size={size}
          target={
            <button
              type='button'
              onClick={!disabled ? openModal : undefined}
              className={`relative box-border w-full py-1.5 border border-grey rounded-md hover:shadow-orange hover:border-transparent text-left transition focus-visible:shadow-orange focus-visible:border focus-visible:border-[#FA4717] outline-none
              ${isOpen ? 'border border-primary outline-0 shadow-orange' : ''}
              ${error ? 'border border-primary outline-0' : ''}
              text-black/40 box-border
              ${isLarge ? 'min-h-[48px]' : 'min-h-[40px] text-sm'}
              `}
            >
              <>
                {isMulti ? (
                  ''
                ) : value?.value ? (
                  <span className={`text-base/[23px] ml-4 text-black`}>{value?.label}</span>
                ) : (
                  <span className={`text-base/[23px] ml-4`}>{placeholder}</span>
                )}
                {!isMulti && <div className='absolute top-0 bottom-0 flex items-center left-4'></div>}
                {isMulti ? (
                  <AreaSelectedValues value={value} setValue={setValue} placeholder={placeholder} onChange={onChange} />
                ) : null}
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    isOpen ? closeModal() : openModal(e);
                  }}
                  className={`absolute right-4 
                  top-0 bottom-0 flex items-center
                  ${isOpen ? 'rotate-180' : ''} transition`}
                >
                  <ArrowIcon width='16' color='#101010' />
                </div>
              </>
            </button>
          }
        >
          <Select
            autoFocus
            backspaceRemovesValue={false}
            components={
              isMulti
                ? {
                    DropdownIndicator,
                    IndicatorSeparator: null,
                    Option,
                  }
                : {
                    DropdownIndicator,
                    IndicatorSeparator: null,
                  }
            }
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={onSelectChange}
            onKeyDown={(event) => event.key === 'Escape' && closeModal()}
            options={options}
            placeholder='Search...'
            styles={selectStyles}
            tabSelectsValue={false}
            value={value}
            maxMenuHeight={200}
            closeMenuOnSelect={false}
            isSearchable={isSearchable}
            isOptionDisabled={(option) =>
              isMulti ? value?.some((v: DropdownDataType) => option.value === v.value) : false
            }
          />
        </DropdownContainer>
        {error && <span className='!m-0 input-error-message'>{error}</span>}
      </div>
    );
  }
);
