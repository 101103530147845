import { RefObject, useEffect } from 'react';

export const useScrollIntoView = (ref: RefObject<HTMLElement>, error: boolean) => {
  useEffect(() => {
    if (error && ref?.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [error, ref]);
};
