import { FC } from 'react';

import { ISvgComponentProps } from '../types';

export const DollarIcon: FC<ISvgComponentProps> = ({ color = '#41BE72', className, width = 10, height = 17 }) => (
  <svg
    viewBox='0 0 10 17'
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    className={className}
    fill={color}
  >
    <path
      id='$'
      d='M4.06193 17V14.5818L4.55373 15.0182C3.65513 14.9939 2.81117 14.8606 2.02186 14.6182C1.23254 14.3758 0.558591 14.0424 0 13.6182L0.801457 11.4C1.36005 11.8 1.9915 12.1152 2.69581 12.3455C3.40012 12.5758 4.14086 12.6909 4.91803 12.6909C5.75592 12.6909 6.35701 12.5576 6.72131 12.2909C7.08561 12.0121 7.26776 11.6788 7.26776 11.2909C7.26776 10.9636 7.14025 10.697 6.88525 10.4909C6.63024 10.2848 6.18701 10.1091 5.55556 9.96364L3.55191 9.52727C2.43473 9.28485 1.60291 8.87273 1.05647 8.29091C0.510018 7.69697 0.236794 6.92727 0.236794 5.98182C0.236794 5.25455 0.412872 4.60606 0.765027 4.03636C1.12933 3.46667 1.6272 3.00606 2.25865 2.65455C2.90225 2.30303 3.65513 2.09091 4.5173 2.01818L4.06193 2.30909V0H6.22951V2.30909L5.77413 1.98182C6.49059 2.0303 7.18883 2.17576 7.86885 2.41818C8.54888 2.64848 9.10747 2.98182 9.54463 3.41818L8.74317 5.49091C8.29387 5.10303 7.77171 4.81212 7.17668 4.61818C6.58166 4.41212 5.93807 4.30909 5.2459 4.30909C4.5173 4.30909 3.94657 4.45455 3.5337 4.74545C3.13297 5.03636 2.9326 5.41212 2.9326 5.87273C2.9326 6.21212 3.05404 6.49091 3.2969 6.70909C3.55191 6.91515 3.983 7.08485 4.59016 7.21818L6.57559 7.65455C7.7292 7.89697 8.58531 8.30303 9.1439 8.87273C9.71463 9.44242 10 10.1818 10 11.0909C10 11.8061 9.82392 12.4424 9.47177 13C9.11961 13.5455 8.63388 13.9879 8.01457 14.3273C7.39526 14.6667 6.67274 14.8848 5.84699 14.9818L6.22951 14.5636V17H4.06193Z'
    />
  </svg>
);
