import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DropdownType } from '../../../types';

import { Dropdown } from './Dropdown';

type DropdownControllerProps = DropdownType & {
  name: string;
  disabled?: boolean;
  onChangeHandler?: (val: any) => void;
};

export const DropdownController: FC<DropdownControllerProps> = ({
  name,
  label = '',
  selectedValue,
  options,
  isMulti = false,
  placeholder = '',
  size = 'large',
  isSearchable = true,
  className,
  disabled = false,
  onChangeHandler = undefined,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={selectedValue}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <>
          <Dropdown
            options={options}
            onChange={(val) => {
              onChange(val);
              onChangeHandler && onChangeHandler(val);
            }}
            label={label}
            placeholder={placeholder}
            size={size}
            isMulti={isMulti}
            selectedValue={selectedValue}
            isSearchable={isSearchable}
            className={className}
            disabled={disabled}
            error={error?.message}
          />
        </>
      )}
    />
  );
};
