import * as yup from 'yup';
import { FC, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import gaussian from 'gaussian';

import { AdvancedOptionsSection, BaseOptionsSection } from './components';
import { Button } from '../../../Button';
import { ICalculationResults } from '../../../../types';
import { useStoreState } from 'easy-peasy';
import { FormValues, StoreModel } from '../../../../store';

// TO DO: mock data for the chart
const data = [
  {
    name: '0.0%',
    value: 90,
  },
  {
    name: '0.8%',
    value: 190,
  },
  {
    name: '1.7%',
    value: 350,
  },
  {
    name: '2.5%',
    value: 250,
  },
  {
    name: '3.3%',
    value: 520,
  },
  {
    name: '4.2%',
    value: 470,
  },
  {
    name: '5.0%',
    value: 280,
  },
  {
    name: '6.0%',
    value: 150,
  },
];

const schema = yup.object({
  numStudentsInPool: yup
    .number()
    .nullable()
    .default(undefined)
    .transform((_, val) => {
      const value = val?.value ?? val ?? null;
      return value && Number(value) ? Number(value) : value;
    })
    .required('This field is required'),
  incomingClassYear: yup
    .string()
    .oneOf(['freshman', 'sophomore', 'junior', 'senior'], 'This field is required')
    .default('senior')
    .transform((_, val) => {
      const value = val?.value ?? val ?? null;
      return value;
    })
    .required('This field is required'),
  highPerformanceStudents: yup
    .number()
    .nullable()
    .default(undefined)
    .transform((_, val) => {
      const value = val?.value ?? val ?? null;
      if (value === '0') {
        // below our boolean evaluates to false if value is '0'
        // because Number('0') is falsy
        return 0;
      }
      return value && Number(value) ? Number(value) : value;
    })
    .required('This field is required'),
  todaysAverageSTEMMajorStartingSalaries: yup.number().transform((_, val) => {
    return val * 1000;
  }),
  todaysAverageNonSTEMMajorStartingSalaries: yup.number().transform((_, val) => {
    return val * 1000;
  }),
  standardDeviationOfSTEMStartingSalaries: yup.number().transform((_, val) => {
    return val * 1000;
  }),
});

interface IProps {
  setShowResults: (value: boolean) => void;
  setCalculationResults: (value: ICalculationResults | null) => void;
}

export const FormSection: FC<IProps> = ({ setShowResults, setCalculationResults }) => {
  const formValues: FormValues = useStoreState<StoreModel>((state) => state.formValues);

  const [isAdvancedOpened, setIsAdvancedOpened] = useState(false);
  const form = useForm({
    defaultValues: formValues,
    resolver: yupResolver(schema),
    criteriaMode: 'firstError',
    mode: 'onChange',
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = form;

  useEffect(() => setCalculationResults(null), [setCalculationResults]);

  const onSubmit = async () => {
    const data = {
      numStudents: formValues.numStudentsInPool,
      maxMonths: formValues.yearsOfFutureIncomeCommitted * 12,
      pctIncome: formValues.studentsPercentageOfIncomeCommitted / 100,
      highPerformerThreshold: 1 - formValues.highPerformanceStudents / 100,
      pctHighPerformersWhoDoNotParticipate: formValues.percentageOfHighPerformersWhoDecideNotToParticipateInAPool / 100,
      pctParticipatingStudentsWhoAttrit:
        formValues.percentageOfStudentsThatDefaultOnCommitmentByLeavingTheWorkforce / 100,
      assumedStartingSalary: formValues.todaysAverageSTEMMajorStartingSalaries * 1000,
      startingSalaryStdev: formValues.standardDeviationOfSTEMStartingSalaries * 1000,
      nonStemStartingSalary: formValues.todaysAverageNonSTEMMajorStartingSalaries * 1000,
      nonStemStartingSalaryStdev: formValues.standardDeviationOfNonSTEMStartingSalaries * 1000,
      assumedAnnualIncreaseInStartingSalaries:
        formValues.annualIncreaseInStartingSalariesFromNowUntilGraduation / 100,
      assumedAnnualRaise: formValues.expectedAnnualRaiseWhileWorking / 100,
      annualRaiseStdev: formValues.standardDeviationOfRaise / 100,
      nonStemAnnualRaise: formValues.expectedAnnualRaiseWhileWorkingNonSTEM * 1000,
      nonStemAnnualRaiseStdev: formValues.standardDeviationOfRaiseNonSTEM * 1000,
      pctStudentsWhoTakeExtraTimeToGraduate: {
        take5Years: (formValues.graduateIn5Years) / 100,
        take6Years: (formValues.graduateIn6Years) / 100,
        take7Years: (formValues.graduateIn7Years) / 100,
      },
      pctLeaveWork1YrMasters: (formValues.ofStudentsWhoLeaveWorkFor1YearMasters) / 100,
      increaseInSalaryFrom1YrMasters: (formValues.expectedIncreaseInAnnualIncome1yrMasters) / 100,
      pctLeaveWork2YrMasters: (formValues.ofStudentsWhoLeaveWorkFor2YearMasters) / 100,
      increaseInSalaryFrom2YrMasters: (formValues.expectedIncreaseInAnnualIncome2yrMasters) / 100,
      rangeOfTimeBeforeGradSchool: [
        formValues.minYearsWorkingBeforeReturningToGradSchool,
        (formValues.minYearsWorkingBeforeReturningToGradSchool) + 5,
      ],
      pctStudentsWhoDelayEmployment:
        ((formValues.delayBy1YearAfterGraduation) + (formValues.delayBy2YearAfterGraduation)) / 100,
      pctStudentsWhoDelayEmploymentBy1Yr: (formValues.delayBy1YearAfterGraduation) / 100,
      pctStudentsWhoDelayEmploymentBy2Yr: (formValues.delayBy2YearAfterGraduation) / 100,
      pctStudentsWhoDropStemMajor: {
        freshman: (formValues.freshman) / 100,
        sophomore: (formValues.sophomore) / 100,
        junior: (formValues.junior) / 100,
        senior: (formValues.senior) / 100,
      },
      investorDiscountRate:
        (formValues.annualDemandedRealReturn + formValues.expectedAnnualInflation) / 100,
      interestEarnedOnEscrow: formValues.floatInterestOnEscrow / 100,
      incomingClassYear: formValues.incomingClassYear as 'freshman' | 'sophomore' | 'junior' | 'senior',
    };

    const API_URL_BASE =
      process.env.NODE_ENV === 'production'
        ? 'https://jurna-calculator-production.up.railway.app'
        : 'http://localhost:3001';

    const result = await fetch(`${API_URL_BASE}/api/run-simulation`, {
      method: 'POST',
      credentials: 'include' as RequestCredentials,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(async (response) => {
      if (response.status !== 200) {
        console.log(response.text);
        console.log(response.statusText);
        const test = await response.json();
        console.log(test);
        if (test.message) {
          console.log(test.message);
        }
        alert(
          'There was a technical error running the pricing simulation with your specified inputs. We have notified our team. Please try again with different inputs, or try again later.'
        );
        return null;
      } else {
        return (await response.json()) as {
          irrMean: number;
          irr25thPercentile: number;
          irrMedian: number;
          irr75thPercentile: number;
          fundingAmt: number;
          npvPlatformFees5pctDiscountRate: number;
          npvPlatformFees7pctDiscountRate: number;
          npvPlatformFees10pctDiscountRate: number;
          npvPlatformFees20pctDiscountRate: number;
          platformFeesFirstYear: number;
          totalDollarAmountOfPlatformFees: number;
          numStudentsWhoParticipate: number;
          stdevOfFundingAmt: number;
        };
      }
    });

    if (result === null) {
      return;
    }

    const dist = gaussian(result.fundingAmt, result.stdevOfFundingAmt);

    const chartData = [
      {
        name: '10th Percentile',
        value: dist.ppf(0.1),
      },
      {
        name: '20th Percentile',
        value: dist.ppf(0.2),
      },
      {
        name: '30th Percentile',
        value: dist.ppf(0.3),
      },
      {
        name: '40th Percentile',
        value: dist.ppf(0.4),
      },
      {
        name: '50th Percentile',
        value: result.fundingAmt,
      },
      {
        name: '60th Percentile',
        value: dist.ppf(0.6),
      },
      {
        name: '70th Percentile',
        value: dist.ppf(0.7),
      },
      {
        name: '80th Percentile',
        value: dist.ppf(0.8),
      },
      {
        name: '90th Percentile',
        value: dist.ppf(0.9),
      },
    ];
    // annualDemandedRealReturn
    // annualIncreaseIn
    setCalculationResults({ chartData, std: result.stdevOfFundingAmt, loanAmount: result.fundingAmt });
    setShowResults(true);
    // TODO: make request
  };

  return (
    <section className='max-w-[522px] mx-auto'>
      <FormProvider {...form}>
        <form
          // onSubmit={handleSubmit(onSubmit)}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className='relative flex flex-col w-full gap-12'
        >
          <div className='pb-[200px] px-2 lg:px-0'>
            <BaseOptionsSection callback={() => setIsAdvancedOpened(true)} isAdvancedOpened={isAdvancedOpened} />
            {isAdvancedOpened && <AdvancedOptionsSection callback={() => setIsAdvancedOpened(false)} />}
          </div>
          <div className='pb-10 h-[200px] bg-gradient-to-b from-white/0 via-white to-white flex justify-center items-end fixed bottom-0 left-[50vw] right-0 z-[4]'>
            <Button
              className='!w-[150px]'
              disabled={!isValid}
              label='Calculate'
              onClick={() => {
                isValid && onSubmit();
              }}
            />
          </div>
        </form>
      </FormProvider>
    </section>
  );
};
