import { FC } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';

import { GreySection } from '../../GreySection';

const colors = [
  '#F9CCC0',
  '#F9B19E',
  '#F99F87',
  '#F98E71',
  '#F97C5A',
  '#F86741',
  '#FA592E',
  '#FA4717',
  '#F04011',
  '#DC3609',
];

interface IProps {
  setShowChart: (value: boolean) => void;
  chartData: { name: string; value: number }[];
}

export const ChartSection: FC<IProps> = ({ setShowChart, chartData }) => {
  return (
    <div className='w-full mt-16 mb-[102px]'>
      <h3 className='font-bold text-center text-lg/4 font-monument -tracking-[1px] mb-8 uppercase'>
        Distribution of Average NPV per Student Across Many Simulations
      </h3>
      <div className='bg-[#F8F8F8] w-full h-fit mb-16 py-6'>
        <BarChart
          width={500}
          height={244}
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barCategoryGap={0}
          barGap={0}
          className='w-full max-w-full'
        >
          <CartesianGrid horizontal={true} vertical={false} fill='#F8F8F8' />
          <XAxis dataKey='name' />
          <YAxis />
          <Bar dataKey='value' stackId='stack'>
            {chartData.map((_, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Bar>
        </BarChart>
      </div>
      <GreySection
        title='Close Distribution Average of NPV'
        subTitle='Quick and intuitive bar control. Click to close analytics chart.'
        buttonLabel='Close'
        callback={() => setShowChart(false)}
      />
    </div>
  );
};
