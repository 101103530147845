import cn from 'classnames';
import { FC } from 'react';

import logo from '../../../../assets/svg/logo-black.svg';

interface IProps {
  withLogo?: boolean;
  title: string;
  subTitle?: string;
  titleClassName?: string;
  subTitleClassName?: string;
}

export const Header: FC<IProps> = ({ withLogo = false, title, subTitle, titleClassName, subTitleClassName }) => (
  <div className={`flex flex-col lg:px-9 px-4 items-center gap-6 ${subTitle ? 'mb-10' : 'mb-12'}`}>
    {withLogo && <img src={logo} alt='Logo' className='mb-8' />}
    <h2 className={cn('text-3xl text-[32px] font-bold font-monument uppercase text-center', titleClassName)}>
      {title}
    </h2>
    {subTitle && <p className={cn('text-[#4D4D4D] text-base text-center', subTitleClassName)}>{subTitle}</p>}
  </div>
);
