import { Action, createStore, action } from 'easy-peasy';

export interface FormValues {
  numStudentsInPool: number;
  yearsOfFutureIncomeCommitted: number;
  studentsPercentageOfIncomeCommitted: number;
  highPerformanceStudents: number;
  percentageOfHighPerformersWhoDecideNotToParticipateInAPool: number;
  percentageOfStudentsThatDefaultOnCommitmentByLeavingTheWorkforce: number;
  todaysAverageSTEMMajorStartingSalaries: number;
  standardDeviationOfSTEMStartingSalaries: number;
  todaysAverageNonSTEMMajorStartingSalaries: number;
  standardDeviationOfNonSTEMStartingSalaries: number;
  annualIncreaseInStartingSalariesFromNowUntilGraduation: number;
  expectedAnnualRaiseWhileWorking: number;
  standardDeviationOfRaise: number;
  expectedAnnualRaiseWhileWorkingNonSTEM: number;
  standardDeviationOfRaiseNonSTEM: number;
  graduateIn5Years: number;
  graduateIn6Years: number;
  graduateIn7Years: number;
  ofStudentsWhoLeaveWorkFor1YearMasters: number;
  expectedIncreaseInAnnualIncome1yrMasters: number;
  ofStudentsWhoLeaveWorkFor2YearMasters: number;
  expectedIncreaseInAnnualIncome2yrMasters: number;
  minYearsWorkingBeforeReturningToGradSchool: number;
  delayBy1YearAfterGraduation: number;
  delayBy2YearAfterGraduation: number;
  freshman: number;
  sophomore: number;
  junior: number;
  senior: number;
  annualDemandedRealReturn: number;
  expectedAnnualInflation: number;
  floatInterestOnEscrow: number;
  incomingClassYear: 'freshman' | 'sophomore' | 'junior' | 'senior';
}

export const defaultFormValues = {
  /*
   * basic inputs
   */
  numStudentsInPool: 250, // numStudents: 250
  incomingClassYear: 'senior' as 'freshman' | 'sophomore' | 'junior' | 'senior', // incomingClassYear: "senior"
  studentsPercentageOfIncomeCommitted: 2.5, // pctIncome: 0.025
  yearsOfFutureIncomeCommitted: 5, // maxMonths: 60 (5 years * 12)
  annualIncreaseInStartingSalariesFromNowUntilGraduation: 3, // assumedAnnualIncreaseInStartingSalaries: 0.03
  todaysAverageSTEMMajorStartingSalaries: 75, // assumedStartingSalary: 75000 (75 * 1000)
  todaysAverageNonSTEMMajorStartingSalaries: 45, // nonStemStartingSalary: 45000 (45 * 1000)
  highPerformanceStudents: 10, // highPerformerThreshold: 0.9 (1 - 0.10)
  percentageOfHighPerformersWhoDecideNotToParticipateInAPool: 15, // pctHighPerformersWhoDoNotParticipate: 0.15
  percentageOfStudentsThatDefaultOnCommitmentByLeavingTheWorkforce: 2, // pctParticipatingStudentsWhoAttrit: 0.02
  expectedAnnualInflation: 3.5, // investorDiscountRate: 0.07 (7 - 4)
  annualDemandedRealReturn: 5, // investorDiscountRate: 0.07 (7 - 3)
  floatInterestOnEscrow: 3, // interestEarnedOnEscrow: 0.03
  /*
   * advanced inputs
   */
  standardDeviationOfSTEMStartingSalaries: 10, // startingSalaryStdev: 10000 (10 * 1000)
  expectedAnnualRaiseWhileWorking: 4, // assumedAnnualRaise: 0.04
  standardDeviationOfRaise: 2, // annualRaiseStdev: 0.02
  standardDeviationOfNonSTEMStartingSalaries: 14, // nonStemStartingSalaryStdev: 14000 (14 * 1000)
  expectedAnnualRaiseWhileWorkingNonSTEM: 3, // nonStemAnnualRaise: 3000 (3000 / 1000)
  standardDeviationOfRaiseNonSTEM: 3, // nonStemAnnualRaiseStdev: 3000 (3000 / 1000)
  minYearsWorkingBeforeReturningToGradSchool: 2, // rangeOfTimeBeforeGradSchool: [2, 7]
  ofStudentsWhoLeaveWorkFor1YearMasters: 10, // pctLeaveWork1YrMasters: 0.1
  expectedIncreaseInAnnualIncome1yrMasters: 15, // increaseInSalaryFrom1YrMasters: 0.15
  ofStudentsWhoLeaveWorkFor2YearMasters: 10, // pctLeaveWork2YrMasters: 0.1
  expectedIncreaseInAnnualIncome2yrMasters: 25, // increaseInSalaryFrom2YrMasters: 0.25
  freshman: 25, // pctStudentsWhoDropStemMajor.freshman: 0.25
  sophomore: 15, // pctStudentsWhoDropStemMajor.sophomore: 0.15
  junior: 4, // pctStudentsWhoDropStemMajor.junior: 0.04
  senior: 1, // pctStudentsWhoDropStemMajor.senior: 0.01
  graduateIn5Years: 5, // take5Years: 0.05
  graduateIn6Years: 2, // take6Years: 0.02
  graduateIn7Years: 1, // take7Years: 0.01
  delayBy1YearAfterGraduation: 3, // pctStudentsWhoDelayEmploymentBy1Yr: 0.03
  delayBy2YearAfterGraduation: 5, // pctStudentsWhoDelayEmploymentBy2Yr: 0.05
};

export interface StoreModel {
  formValues: FormValues;
  updateFormValue: Action<StoreModel, { key: keyof FormValues; value: any }>;
}

const storeModel: StoreModel = {
  formValues: { ...defaultFormValues }, // Initialize with default values
  updateFormValue: action((state, { key, value }) => {
    state.formValues = { ...state.formValues, [key]: value };
  }),
};

export const store = createStore(storeModel);
