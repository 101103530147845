import { StylesConfig } from 'react-select';

import { DropdownDataType } from '../../types';

import search from '../../../../assets/svg/search.svg';

export const selectStyles: StylesConfig<DropdownDataType, false> = {
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isFocused ? '#F8F8F8' : state.isSelected ? '#F8F8F8' : '#FFFFFF',
      ':active': {
        backgroundColor: '#F8F8F8',
      },
      borderRadius: 4,
      fontSize: 'inherit',
      lineHeight: '14px',
      color: state.isSelected ? '#101010' : 'rgba(16, 16, 16, 0.7)',
      display: 'flex',
      alignItems: 'flex-end',
      gap: 10,
      ':hover': {
        color: 'rgba(16, 16, 16, 0.8)',
      },
    };
  },
  control: (provided, props) => ({
    ...provided,
    display: props.selectProps.isSearchable ? 'block' : 'none',
    minWidth: 'inherit',
    paddingLeft: 32,
    margin: 8,
    border: '1px solid #DEDEDE',
    borderRadius: 6,
    height: 40,
    minHeight: 40,
    width: '-webkit-fill-available',
    fontSize: 14,
    background: `url(${search}) left 16px top 12px no-repeat #fff`,
    boxSizing: 'border-box',
    outline: 'none',
    lineHeight: '30px',
    boxShadow: 'none',
    ':hover': {
      border: '1px solid #DEDEDE',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(16, 16, 16, 0.4)',
  }),
  menu: () => ({
    margin: 6,
  }),
};
