import { FC } from 'react';

import { ISvgComponentProps } from '../types';

export const ArrowIcon: FC<ISvgComponentProps> = ({ color = 'currentColor', className, width, height }) => (
  <svg
    fill={color}
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.80561 5.46967C3.0985 5.17678 3.57337 5.17678 3.86627 5.46967L8.0026 9.60601L12.1389 5.46967C12.4318 5.17678 12.9067 5.17678 13.1996 5.46967C13.4925 5.76256 13.4925 6.23744 13.1996 6.53033L8.53293 11.197C8.24004 11.4899 7.76517 11.4899 7.47227 11.197L2.80561 6.53033C2.51271 6.23744 2.51271 5.76256 2.80561 5.46967Z'
    />
  </svg>
);
