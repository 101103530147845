import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { DropdownController } from '../../../../../Inputs';
import { FormSectionWrapper } from '../FormSectionWrapper';
import { Header } from '../../../Header';
import { RangeSlider } from '../../../../../RangeSlider';
import { GreySection } from '../../../GreySection';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FormValues, StoreModel } from '../../../../../../store';

interface IProps {
  callback: () => void;
  isAdvancedOpened: boolean;
}

export const BaseOptionsSection: FC<IProps> = ({ callback, isAdvancedOpened }) => {
  const formValues: FormValues = useStoreState<StoreModel>((state) => state.formValues);
  const updateFormValue = useStoreActions<StoreModel>((state) => state.updateFormValue);

  const { control } = useFormContext();

  return (
    <>
      <Header
        withLogo
        title='Investment Calculator'
        subTitle='Calculate your investment return. The Jurna Investment Calculator helps investors assess return.'
      />
      <FormSectionWrapper title='Pool Attributes' className='px-6 mb-12'>
        <div className='flex flex-col gap-4'>
          <DropdownController
            name='numStudentsInPool'
            label='Number of Students in the Pool'
            selectedValue={{
              value: `${formValues.numStudentsInPool}`,
              label: `${formValues.numStudentsInPool}`,
            }}
            onChangeHandler={(value) => {
              updateFormValue({
                key: 'numStudentsInPool',
                value: parseInt(value.value),
              });
            }}
            options={[
              {
                value: '250',
                label: '250',
              },
              {
                value: '500',
                label: '500',
              },
              {
                value: '750',
                label: '750',
              },
              {
                value: '1000',
                label: '1000',
              },
            ]}
            placeholder='250'
            className='w-full'
            isSearchable={false}
          />
          <DropdownController
            name='incomingClassYear'
            label='Class Year of Students'
            // selectedValue={getValues('incomingClassYear')}
            selectedValue={{
              value: `${formValues.incomingClassYear}`,
              // uppercase the first letter for the label
              label: `${formValues.incomingClassYear.charAt(0).toUpperCase()}${formValues.incomingClassYear.slice(1)}`,
            }}
            onChangeHandler={(value) => {
              updateFormValue({
                key: 'incomingClassYear',
                value: value.value,
              });
            }}
            options={[
              {
                value: 'freshman',
                label: 'Freshman',
              },
              {
                value: 'sophomore',
                label: 'Sophomore',
              },
              {
                value: 'junior',
                label: 'Junior',
              },
              {
                value: 'senior',
                label: 'Senior',
              },
            ]}
            placeholder='Senior'
            className='w-full'
            isSearchable={false}
          />
        </div>
        <Controller
          control={control}
          name='studentsPercentageOfIncomeCommitted'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label="Students' Percentage of Income Committed"
              min={1}
              max={15}
              defaultValue={formValues.studentsPercentageOfIncomeCommitted}
              step={0.5}
              minValueSuffix='%'
              maxValueSuffix='%'
              tooltipValueSuffix='%'
              onChange={(value) => {
                updateFormValue({
                  key: 'studentsPercentageOfIncomeCommitted',
                  value,
                });
              }}
            />
          )}
        />
        <Controller
          control={control}
          name='yearsOfFutureIncomeCommitted'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label='Years of Future Income Committed'
              min={5}
              max={12}
              defaultValue={formValues.yearsOfFutureIncomeCommitted}
              step={1}
              onChange={(value) => {
                updateFormValue({
                  key: 'yearsOfFutureIncomeCommitted',
                  value,
                });
              }}
            />
          )}
        />
      </FormSectionWrapper>
      <FormSectionWrapper title='Earnings Attributes' className='px-6 mb-12'>
        <Controller
          control={control}
          name='annualIncreaseInStartingSalariesFromNowUntilGraduation'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label='Annual Increase in Starting Salaries from now until Graduation'
              min={0}
              max={10}
              defaultValue={formValues.annualIncreaseInStartingSalariesFromNowUntilGraduation}
              step={1}
              onChange={(value) => {
                updateFormValue({
                  key: 'annualIncreaseInStartingSalariesFromNowUntilGraduation',
                  value,
                });
              }}
            />
          )}
        />
        <Controller
          control={control}
          name='todaysAverageSTEMMajorStartingSalaries'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label="Today's Average STEM Major Starting Salaries"
              min={25}
              max={200}
              defaultValue={formValues.todaysAverageSTEMMajorStartingSalaries}
              step={1}
              onChange={(value) => {
                updateFormValue({
                  key: 'todaysAverageSTEMMajorStartingSalaries',
                  value,
                });
              }}
              minValuePrefix='$ '
              minValueSuffix='K'
              maxValuePrefix='$ '
              maxValueSuffix='K'
              tooltipValuePrefix='$ '
              tooltipValueSuffix='K'
            />
          )}
        />
        <Controller
          control={control}
          name='todaysAverageNonSTEMMajorStartingSalaries'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label="Today's Average Non-STEM Major Starting Salaries"
              min={25}
              max={200}
              defaultValue={formValues.todaysAverageNonSTEMMajorStartingSalaries}
              step={1}
              onChange={(value) => {
                updateFormValue({
                  key: 'todaysAverageNonSTEMMajorStartingSalaries',
                  value,
                });
              }}
              minValuePrefix='$ '
              minValueSuffix='K'
              maxValuePrefix='$ '
              maxValueSuffix='K'
              tooltipValuePrefix='$ '
              tooltipValueSuffix='K'
            />
          )}
        />
      </FormSectionWrapper>
      <FormSectionWrapper title='Potential Selection Bias and Default' className='px-6 mb-12'>
        <div className='flex flex-col gap-4'>
          <DropdownController
            name='highPerformanceStudents'
            label='Percentage of Students Who are Considered High Performers'
            options={[
              {
                value: '0',
                label: 'None',
              },
              {
                value: '5',
                label: 'Top 5%',
              },
              {
                value: '10',
                label: 'Top 10%',
              },
              {
                value: '15',
                label: 'Top 15%',
              },
              {
                value: '20',
                label: 'Top 20%',
              },
              {
                value: '25',
                label: 'Top 25',
              },
              {
                value: '30',
                label: 'Top 30%',
              },
            ]}
            selectedValue={{
              value: `${formValues.highPerformanceStudents}`,
              label: `Top ${formValues.highPerformanceStudents}%`,
            }}
            onChangeHandler={(e) => {
              updateFormValue({
                key: 'highPerformanceStudents',
                value: parseInt(e.value),
              });
            }}
            placeholder='Top 10%'
            isSearchable={false}
          />
          <Controller
            control={control}
            name='percentageOfHighPerformersWhoDecideNotToParticipateInAPool'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Percentage of High Performers Who Decide Not to Participate in a Pool'
                min={0}
                max={100}
                defaultValue={formValues.percentageOfHighPerformersWhoDecideNotToParticipateInAPool}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'percentageOfHighPerformersWhoDecideNotToParticipateInAPool',
                    value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
          <div className='flex items-center justify-between p-6 mb-6 bg-[#F8F8F8] border border-[#F0F0F0] rounded-md'>
            <div className='w-1/2 text-left text-sm/4 text-black/60'>
              Total % of Students Who Opt Out of Participation:
            </div>
            <div className='text-2xl font-medium text-[#FA4717] w-fit'>
              {(formValues.highPerformanceStudents *
                formValues.percentageOfHighPerformersWhoDecideNotToParticipateInAPool) /
                100}
              %
            </div>
          </div>
          <Controller
            control={control}
            name='percentageOfStudentsThatDefaultOnCommitmentByLeavingTheWorkforce'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Percentage of Students that Default on Commitment by Leaving the Workforce'
                min={0}
                max={40}
                defaultValue={formValues.percentageOfStudentsThatDefaultOnCommitmentByLeavingTheWorkforce}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'percentageOfStudentsThatDefaultOnCommitmentByLeavingTheWorkforce',
                    value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
        </div>
      </FormSectionWrapper>
      <FormSectionWrapper title='Investors Required Return' className='px-6 mb-12'>
        <Controller
          control={control}
          name='expectedAnnualInflation'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label='Expected Annual Inflation'
              min={0}
              max={15}
              defaultValue={formValues.expectedAnnualInflation}
              step={0.5}
              onChange={(value) => {
                updateFormValue({
                  key: 'expectedAnnualInflation',
                  value,
                });
              }}
              minValueSuffix='%'
              maxValueSuffix='%'
              tooltipValueSuffix='%'
            />
          )}
        />
        <Controller
          control={control}
          name='annualDemandedRealReturn'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label='Annual Demanded Real Return'
              min={0}
              max={6.5}
              defaultValue={formValues.annualDemandedRealReturn}
              step={0.5}
              onChange={(value) => {
                updateFormValue({
                  key: 'annualDemandedRealReturn',
                  value,
                });
              }}
              minValueSuffix='%'
              maxValueSuffix='%'
              tooltipValueSuffix='%'
            />
          )}
        />
        <div className='flex items-center justify-between p-6 mb-6 bg-[#F8F8F8] border border-[#F0F0F0] rounded-md'>
          <div className='w-1/2 text-left text-sm/4 text-black/60'>Nominal Investor Return (Discount Rate)</div>
          <div className='text-2xl font-medium text-[#FA4717] w-fit'>{`${formValues.expectedAnnualInflation +
            formValues.annualDemandedRealReturn}%`}</div>
        </div>
      </FormSectionWrapper>
      <FormSectionWrapper
        title='Fees'
        subTitle='Jurna charges a 2% Origination Fee and 1% Administration Fee. Additionally Jurna shares the Float Interest with investors to maximize investors investment offer to the students.'
        className='px-6 mb-12'
      >
        <Controller
          control={control}
          name='floatInterestOnEscrow'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label='Expected Float Interest on Escrow'
              min={0}
              max={10}
              defaultValue={formValues.floatInterestOnEscrow}
              step={1}
              onChange={(value) => {
                updateFormValue({
                  key: 'floatInterestOnEscrow',
                  value,
                });
              }}
              minValueSuffix='%'
              maxValueSuffix='%'
              tooltipValueSuffix='%'
            />
          )}
        />
        <div className='flex gap-4 mb-10'>
          <div className='w-1/2 flex items-center justify-between p-6 py-2 mb-6 bg-[#F8F8F8] border border-[#F0F0F0] rounded-md'>
            <div className='px-2 text-left text-sm/4 text-black/60'>Paid to Jurna</div>
            <div className='text-2xl font-medium w-fit'>{`${formValues.floatInterestOnEscrow / 2}%`}</div>
          </div>
          <div className='w-1/2 flex items-center justify-between p-6 py-2 mb-6 bg-[#F8F8F8] border border-[#F0F0F0] rounded-md'>
            <div className='px-2 text-left text-sm/4 text-black/60'>Paid to Investors</div>
            <div className='text-2xl font-medium w-fit'>{`${formValues.floatInterestOnEscrow / 2}%`}</div>
          </div>
        </div>
        {!isAdvancedOpened && (
          <GreySection
            title='View advanced options'
            subTitle='Provide more detailed inputs. Click here to access advanced options.'
            buttonLabel='Open'
            callback={callback}
          />
        )}
      </FormSectionWrapper>
    </>
  );
};
