export function getRandomUniqueArray(length: number) {
  if (length <= 0) {
    return [];
  }

  if (length > 1000) {
    console.warn('Warning: Generating a large array of unique values may take some time.');
  }

  const uniqueSet: Set<number> = new Set();
  const uniqueArray: Array<number> = [];

  while (uniqueSet.size < length) {
    const randomValue = Math.floor(Math.random() * 1000000);
    uniqueSet.add(randomValue);
  }

  uniqueSet.forEach((value) => {
    uniqueArray.push(value);
  });

  return uniqueArray;
}
