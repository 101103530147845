import React, { ComponentProps, FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IContainedButtonProps, IOutlinedButtonProps } from './types';

type ButtonType = ComponentProps<'button'> & (IContainedButtonProps | IOutlinedButtonProps);

export const Button: FC<ButtonType> = ({
  variant = 'contained',
  disabled,
  onClick,
  className = '',
  label,
  ...otherProps
}) => {
  const classes = twMerge(`btn-${variant}`, className);

  return (
    <button className={classes} disabled={disabled} onClick={onClick} {...otherProps}>
      {label}
    </button>
  );
};
