import { FC } from 'react';

import { Logo, TopRow, BottomRow } from './components';

export const FixedSection: FC = () => {
  return (
    <aside className='flex flex-1 flex-col bg-ui-gray-700 text-ui-gray-10 fixed w-[50vw] min-h-screen pb-16 pt-10'>
      <Logo />
      <section className='w-full m-auto'>
        <TopRow />
        <BottomRow />
      </section>
    </aside>
  );
};
