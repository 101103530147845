import { FC, useState } from 'react';
import { Button } from '../../../Button';
import { Header } from '../Header';
import _std from '../../../../assets/svg/std.svg';
import dollar from '../../../../assets/svg/dollar.svg';
import { ChartSection } from './components';
import { ICalculationResults } from '../../../../types';
import { GreySection } from '../GreySection';

interface IProps {
  setShowResults: (value: boolean) => void;
  calculationResults: ICalculationResults;
}

function formatCurrency(amount: number, currency: string) {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(amount);
}

export const ResultsSection: FC<IProps> = ({ setShowResults, calculationResults }) => {
  const [showChart, setShowChart] = useState(false);
  const { std, loanAmount, chartData } = calculationResults;

  return (
    <section className='max-w-[532px] min-h-screen pb-[200px] mx-auto flex flex-col px-4 xl:px-0'>
      <Header
        title='Investment Calculator Results'
        subTitle='Based on the inputs you selected, your investment would be:'
        subTitleClassName='max-w-[274px] mx-auto'
      />
      <div className='flex items-center justify-between p-6 border border-[#F0F0F0] rounded-lg mb-4'>
        <div className='flex items-center text-left w-fit'>
          <img src={dollar} alt='Dollar icon' className='mr-2.5' />
          <h3 className='font-medium text-base/4'>Loan Amount:</h3>
        </div>
        <div className='w-fit'>
          <p>
            <span className='text-[#FA4717] font-medium text-4xl'>
              {formatCurrency(Math.floor(loanAmount * 100) / 100, 'USD')}{' '}
            </span>
            / per Student
          </p>
        </div>
      </div>

      <div className='flex items-center justify-between p-6 border border-[#F0F0F0] rounded-lg mb-2'>
        <div className='flex items-center w-1/2 text-left'>
          <img src={_std} alt='Standard deviation icon' className='mr-2.5' />
          <h3 className='font-medium text-base/4'>Standard Deviation Around Calculated Loan Amount*</h3>
        </div>
        <div className='w-fit'>
          <p className='text-4xl font-medium'>{`$ ${std.toLocaleString()}`}</p>
        </div>
      </div>
      <p className='mb-12 text-xs text-black/60'>
        <span className='align-top'>*</span> based on output from multiple simulations
      </p>

      {showChart ? (
        <ChartSection setShowChart={setShowChart} chartData={chartData} />
      ) : (
        <GreySection
          className='mb-[102px]'
          title='View Distribution Average of NPV'
          subTitle='Discover valuable data instantly. Click to open analytics chart.'
          buttonLabel='Open'
          callback={() => setShowChart(true)}
        />
      )}
      <div className='pb-10 h-[200px] fixed bottom-0 left-[50vw] right-0 z-10 bg-white flex items-end'>
        <div className='max-w-[375px] mx-auto flex flex-col items-center justify-end'>
          <p className='mb-6 text-center'>
            Easily revisit the calculator to make more informed financial decisions whenever you need.
          </p>
          <Button
            type='button'
            onClick={() => setShowResults(false)}
            className='!max-w-[180px] mx-auto !px-4'
            label='Back to Calculator'
          />
        </div>
      </div>
    </section>
  );
};
