import { FC, memo } from 'react';

import { DropdownDataType } from '../../../types';

type SelectedValueType = {
  value: DropdownDataType[];
  setValue: (value: DropdownDataType[]) => void;
  onChange?: (value: any) => void;
  placeholder?: string;
};

export const AreaSelectedValues: FC<SelectedValueType> = memo(({ value, setValue, onChange, placeholder = '' }) => {
  return (
    <>
      {!!value?.length && (
        <div className='flex overflow-hidden flex-wrap gap-y-1.5 pr-5'>
          {value?.map((v) => (
            <div
              key={v.value}
              className='text-black bg-[#F6F6F6] rounded pl-3 text-sm h-[34px] flex
            items-center justify-around ml-1.5 max-w-32'
            >
              <span className='truncate'>{v.label}</span>
              <span
                className='mx-2'
                onClick={() => {
                  const newValue = value.filter((i) => i.value !== v.value);
                  onChange && onChange(newValue);
                  setValue(newValue);
                }}
              >
                ✕
              </span>
            </div>
          ))}
          <span className='ml-3 leading-[34px] truncate'>{placeholder}</span>
        </div>
      )}
      {!value?.length && <span className='ml-3 leading-8 truncate'>{placeholder}</span>}
    </>
  );
});
