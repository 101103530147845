import card1 from '../../../assets/images/card1.png';
import card2 from '../../../assets/images/card2.png';
import chart from '../../../assets/svg/chart.svg';

import { DollarIcon } from '../../Icons';

export const TopRow = () => (
  <div className='flex w-full max-w-[512px] mx-auto items-center px-2'>
    <div className='flex flex-col w-1/2'>
      <img src={card1} alt='Discover card One' className='h-auto max-w-[260px] w-full mb-6' />
      <img src={card2} alt='Discover card Two' className='h-auto max-w-[260px] w-full' />
    </div>
    <div className='flex justify-center w-1/2'>
      <div className='flex flex-col w-fit'>
        <div className='flex items-baseline ml-1'>
          <span className='text-3xl font-bold text-white font-monument'>25M</span>
          <DollarIcon className='ml-0.5' />
        </div>
        <span className='text-white/70 text-sm/4 mb-[6px] ml-1'>Avg Pool Size</span>
        <img src={chart} alt='Chart' className='w-full max-w-[133px] to mx-auto' />
      </div>
    </div>
  </div>
);
