// ScrollProgressBar.tsx
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import cn from 'classnames';

export interface ScrollProgressBarProps {
  className?: string;
  progressClassName?: string;
}

export const ScrollProgressBar: React.FC<ScrollProgressBarProps> = ({ className, progressClassName }) => {
  const [scrollPercentage, setScrollPercentage] = useState<number>(0);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const fullHeight = document.body.clientHeight;
    const progress = (scrollY / (fullHeight - windowHeight)) * 100;
    setScrollPercentage(progress);
  };

  useEffect(() => {
    const handleDocumentResize = () => {
      handleScroll();
    };

    window.addEventListener('scroll', handleScroll);
    const resizeObserver = new ResizeObserver(handleDocumentResize);
    resizeObserver.observe(document.documentElement);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      resizeObserver.unobserve(document.documentElement);
    };
  }, []);

  return (
    <div className={twMerge(cn('h-[7px] bg-ui-gray-50 fixed top-0 left-[50vw] right-0 z-50', className))}>
      <div
        className={twMerge(cn('h-[7px] bg-ui-primary-400', progressClassName))}
        style={{ width: `${scrollPercentage}%` }}
      />
    </div>
  );
};
