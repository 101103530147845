import { FC, useState } from 'react';

import { FormSectionWrapper } from '../FormSectionWrapper';
import { Header } from '../../../Header';
import { Controller, useFormContext } from 'react-hook-form';
import { RangeSlider } from '../../../../../RangeSlider';
import { GreySection } from '../../../GreySection';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FormValues, StoreModel } from '../../../../../../store';

interface IProps {
  callback: () => void;
}

export const AdvancedOptionsSection: FC<IProps> = ({ callback }) => {
  const formValues: FormValues = useStoreState<StoreModel>((state) => state.formValues);
  const updateFormValue = useStoreActions<StoreModel>((state) => state.updateFormValue);
  const { control } = useFormContext();

  return (
    <>
      <Header title='Advanced Inputs' />
      <h3 className='text-lg/4.5 font-medium capitalize mb-4 px-6'>Advanced Earnings Inputs</h3>
      <FormSectionWrapper
        title='STEM'
        titleClassName='!text-sm'
        className='p-6 border border-[#DEDEDE] rounded-[10px] mb-4'
      >
        <div className='flex flex-col gap-6'>
          <Controller
            control={control}
            name='standardDeviationOfSTEMStartingSalaries'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Standard Deviation of STEM Starting Salaries'
                min={5}
                max={50}
                defaultValue={formValues.standardDeviationOfSTEMStartingSalaries}
                step={1}
                minValuePrefix='$ '
                minValueSuffix='K'
                maxValuePrefix='$ '
                maxValueSuffix='K'
                tooltipValuePrefix='$ '
                tooltipValueSuffix='K'
                onChange={(value) => {
                  updateFormValue({
                    key: 'standardDeviationOfSTEMStartingSalaries',
                    value: value,
                  });
                }}
              />
            )}
          />
          <Controller
            control={control}
            name='expectedAnnualRaiseWhileWorking'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Expected Annual Raise While Working'
                min={0}
                max={15}
                defaultValue={formValues.expectedAnnualRaiseWhileWorking}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'expectedAnnualRaiseWhileWorking',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
          <Controller
            control={control}
            name='standardDeviationOfRaise'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Standard Deviation of Raise'
                min={0}
                max={10}
                defaultValue={formValues.standardDeviationOfRaise}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'standardDeviationOfRaise',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
        </div>
      </FormSectionWrapper>
      <FormSectionWrapper
        title='Non-STEM'
        titleClassName='!text-sm'
        className='p-6 border border-[#DEDEDE] rounded-[10px] mb-12'
      >
        <div className='flex flex-col gap-6'>
          <Controller
            control={control}
            name='standardDeviationOfNonSTEMStartingSalaries'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Standard Deviation of Non-STEM Starting Salaries'
                min={5}
                max={50}
                defaultValue={formValues.standardDeviationOfNonSTEMStartingSalaries}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'standardDeviationOfNonSTEMStartingSalaries',
                    value: value,
                  });
                }}
                minValuePrefix='$ '
                minValueSuffix='K'
                maxValuePrefix='$ '
                maxValueSuffix='K'
                tooltipValuePrefix='$ '
                tooltipValueSuffix='K'
              />
            )}
          />
          <Controller
            control={control}
            name='expectedAnnualRaiseWhileWorkingNonSTEM'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Expected Annual Raise While Working'
                min={0}
                max={15}
                defaultValue={formValues.expectedAnnualRaiseWhileWorkingNonSTEM}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'expectedAnnualRaiseWhileWorkingNonSTEM',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
          <Controller
            control={control}
            name='standardDeviationOfRaiseNonSTEM'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Standard Deviation of Raise'
                min={0}
                max={10}
                defaultValue={formValues.standardDeviationOfRaiseNonSTEM}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'standardDeviationOfRaiseNonSTEM',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
        </div>
      </FormSectionWrapper>
      <FormSectionWrapper title='Advanced Graduate School Attributes' className='!gap-5 px-5 mb-12'>
        <Controller
          control={control}
          name='minYearsWorkingBeforeReturningToGradSchool'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label='Minimum Years of Work Before Returning to Grad School'
              min={0}
              max={10}
              defaultValue={formValues.minYearsWorkingBeforeReturningToGradSchool}
              step={1}
              onChange={(value) => {
                updateFormValue({
                  key: 'minYearsWorkingBeforeReturningToGradSchool',
                  value: value,
                });
              }}
              minValueSuffix='yr'
              maxValueSuffix='yrs'
              tooltipFormatter={(value) => (value > 1 ? `${value} yrs` : `${value} yr`)}
            />
          )}
        />
        <div className='flex items-center justify-between p-6 mb-6 bg-[#F8F8F8] border border-[#F0F0F0] rounded-md'>
          <div className='w-1/2 text-left text-sm/4 text-black/60'>
            Range of Time Working Before Returning To Grad School
          </div>
          <div className='text-2xl font-medium text-[#FA4717] w-fit'>{`${
            formValues.minYearsWorkingBeforeReturningToGradSchool
          } - ${formValues.minYearsWorkingBeforeReturningToGradSchool + 5}yrs`}</div>
        </div>
        <Controller
          control={control}
          name='ofStudentsWhoLeaveWorkFor1YearMasters'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label='% of Students Who Leave Work for 1 Year Masters'
              min={0}
              max={100}
              defaultValue={formValues.ofStudentsWhoLeaveWorkFor1YearMasters}
              step={1}
              onChange={(value) => {
                updateFormValue({
                  key: 'ofStudentsWhoLeaveWorkFor1YearMasters',
                  value: value,
                });
              }}
              minValueSuffix='%'
              maxValueSuffix='%'
              tooltipValueSuffix='%'
            />
          )}
        />
        <Controller
          control={control}
          name='expectedIncreaseInAnnualIncome1yrMasters'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label='Expected % Increase in Annual Income'
              min={0}
              max={100}
              defaultValue={formValues.expectedIncreaseInAnnualIncome1yrMasters}
              step={1}
              onChange={(value) => {
                updateFormValue({
                  key: 'expectedIncreaseInAnnualIncome1yrMasters',
                  value: value,
                });
              }}
              minValueSuffix='%'
              maxValueSuffix='%'
              tooltipValueSuffix='%'
            />
          )}
        />
        <Controller
          control={control}
          name='ofStudentsWhoLeaveWorkFor2YearMasters'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label='% of Students Who Leave Work for 2 Year Masters'
              min={0}
              max={100}
              defaultValue={formValues.ofStudentsWhoLeaveWorkFor2YearMasters}
              step={1}
              onChange={(value) => {
                updateFormValue({
                  key: 'ofStudentsWhoLeaveWorkFor2YearMasters',
                  value: value,
                });
              }}
              minValueSuffix='%'
              maxValueSuffix='%'
              tooltipValueSuffix='%'
            />
          )}
        />
        <Controller
          control={control}
          name='expectedIncreaseInAnnualIncome2yrMasters'
          render={({ field: { onChange } }) => (
            <RangeSlider
              label='Expected % Increase in Annual Income'
              min={0}
              max={100}
              defaultValue={formValues.expectedIncreaseInAnnualIncome2yrMasters}
              step={1}
              onChange={(value) => {
                updateFormValue({
                  key: 'expectedIncreaseInAnnualIncome2yrMasters',
                  value: value,
                });
              }}
              minValueSuffix='%'
              maxValueSuffix='%'
              tooltipValueSuffix='%'
            />
          )}
        />
      </FormSectionWrapper>
      <h3 className='text-lg/4.5 font-medium capitalize mb-4 px-6'>Advanced Undergrad Education Inputs</h3>
      <FormSectionWrapper
        title='Drop STEM Major by Class Year'
        titleClassName='!text-sm'
        className='p-6 border border-[#DEDEDE] rounded-[10px] mb-4'
      >
        <div className='flex flex-col gap-6'>
          <Controller
            control={control}
            name='freshman'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Freshman'
                min={0}
                max={100}
                defaultValue={formValues.freshman}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'freshman',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
          <Controller
            control={control}
            name='sophomore'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Sophomore'
                min={0}
                max={100}
                defaultValue={formValues.sophomore}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'sophomore',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
          <Controller
            control={control}
            name='junior'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Junior'
                min={0}
                max={100}
                defaultValue={formValues.junior}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'junior',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
          <Controller
            control={control}
            name='senior'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Senior'
                min={0}
                max={100}
                defaultValue={formValues.senior}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'senior',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
        </div>
      </FormSectionWrapper>
      <FormSectionWrapper
        title='Extra time to Graduate Undergrad'
        titleClassName='!text-sm'
        className='p-6 border border-[#DEDEDE] rounded-[10px] mb-4'
      >
        <div className='flex flex-col gap-6'>
          <Controller
            control={control}
            name='graduateIn5Years'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Graduate in 5 years'
                min={0}
                max={25}
                defaultValue={formValues.graduateIn5Years}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'graduateIn5Years',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
          <Controller
            control={control}
            name='graduateIn6Years'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Graduate in 6 years'
                min={0}
                max={25}
                defaultValue={formValues.graduateIn6Years}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'graduateIn6Years',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
          <Controller
            control={control}
            name='graduateIn7Years'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Graduate in 7 years'
                min={0}
                max={25}
                defaultValue={formValues.graduateIn7Years}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'graduateIn7Years',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
        </div>
      </FormSectionWrapper>
      <FormSectionWrapper
        title='Delayed Employment'
        titleClassName='!text-sm'
        className='p-6 border border-[#DEDEDE] rounded-[10px] mb-10'
      >
        <div className='flex flex-col gap-6'>
          <Controller
            control={control}
            name='delayBy1YearAfterGraduation'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Delay by 1 year after Graduation'
                min={0}
                max={25}
                defaultValue={formValues.delayBy1YearAfterGraduation}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'delayBy1YearAfterGraduation',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
          <Controller
            control={control}
            name='delayBy2YearAfterGraduation'
            render={({ field: { onChange } }) => (
              <RangeSlider
                label='Delay by 2 year after Graduation'
                min={0}
                max={25}
                defaultValue={formValues.delayBy2YearAfterGraduation}
                step={1}
                onChange={(value) => {
                  updateFormValue({
                    key: 'delayBy2YearAfterGraduation',
                    value: value,
                  });
                }}
                minValueSuffix='%'
                maxValueSuffix='%'
                tooltipValueSuffix='%'
              />
            )}
          />
        </div>
      </FormSectionWrapper>
      <div className='px-6'>
        <GreySection
          title='Advanced options unlocked'
          subTitle='Advanced options unlocked. Click here to close and continue investing.'
          buttonLabel='Close'
          callback={callback}
        />
      </div>
    </>
  );
};
