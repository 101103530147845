import avatars from '../../../assets/svg/avatars.svg';
import chart2 from '../../../assets/svg/chart2.svg';
import people from '../../../assets/images/people.png';

export const BottomRow = () => (
  <div className='flex max-w-[512px] w-full mx-auto pt-8 xl:pt-11 items-start px-4 lg:px-0'>
    <div className='flex w-1/2'>
      <div className='flex ml-auto mr-6'>
        <img src={avatars} alt='Avatars' className='xl:w-full h-auto mr-2 xl:mr-5 w-9/12 max-w-[106px]' />
        <div className='flex flex-col justify-center'>
          <span className='font-bold text-white -tracking-[.869px] text-base/5 font-monument'>50K</span>
          <span className='text-right text-white/70 text-sm/4'>Users</span>
        </div>
      </div>
    </div>
    <div className='w-1/2'>
      <div className='relative'>
        <img src={people} alt='People' className='w-full h-auto max-w-[226px]' />
        <img src={chart2} alt='Chart' width={55} className='absolute right-0 -top-[31px]' />
      </div>
    </div>
  </div>
);
