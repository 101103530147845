import cn from 'classnames';
import { FC, ReactNode } from 'react';

interface IProps {
  title: string;
  subTitle?: string;
  children: ReactNode | ReactNode[];
  className?: string;
  titleClassName?: string;
}
export const FormSectionWrapper: FC<IProps> = ({ title, subTitle, className, titleClassName, children }) => (
  <div className={cn('flex flex-col gap-6', className)}>
    <h2 className={cn('text-lg/4.5 font-medium capitalize', titleClassName)}>{title}</h2>
    {subTitle && <p className='mt-4 text-sm text-left text-black/60'>{subTitle}</p>}
    {children}
  </div>
);
