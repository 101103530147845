import { FixedSection } from './components/FixedSection';
import { ScrollProgressBar } from './components/ScrollProgressBar/ScrollProgressBar';
import { MainSection } from './components/MainSection';
import { StoreProvider } from 'easy-peasy';
import { store } from './store';

function App() {
  return (
    <>
      <StoreProvider store={store}>
        <FixedSection />
        <main className='flex-1 pl-[50vw]'>
          <ScrollProgressBar />
          <section className='h-full pt-[71px] mx-auto'>
            <MainSection />
          </section>
        </main>
      </StoreProvider>
    </>
  );
}

export default App;
