import { FC, ReactNode, memo } from 'react';

export const Menu: FC<{
  children: ReactNode;
  onClose: () => void;
  isMulti: boolean;
  size: 'small' | 'large';
}> = memo(({ isMulti, ...props }) => {
  return (
    <div
      className={`absolute w-full border border-liteGrey rounded-md mt-2 bg-white z-10 after:absolute after:w-full after:h-9 shadow-[0_2px_12px_-6px_rgba(50,11,0,0.08)]
      ${props.size === 'large' ? 'text-sm' : 'text-xs'}`}
    >
      <div {...props} />
    </div>
  );
});
