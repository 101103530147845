import { useState } from 'react';

import { ResultsSection, FormSection } from './components';
import { ICalculationResults } from '../../types';

export const MainSection = () => {
  const [showResults, setShowResults] = useState(false);
  const [calculationResults, setCalculationResults] = useState<ICalculationResults | null>(null);

  return (
    <>
      {showResults && calculationResults ? (
        <ResultsSection setShowResults={setShowResults} calculationResults={calculationResults} />
      ) : (
        <FormSection setShowResults={setShowResults} setCalculationResults={setCalculationResults} />
      )}
    </>
  );
};
