import { FC, memo } from 'react';

import { DropdownContainerProps } from '../../../types';

import { Menu } from './Menu';

export const DropdownContainer: FC<DropdownContainerProps> = memo(
  ({ children, isOpen, target, onClose, isMulti, size }) => {
    return (
      <div style={{ position: 'relative' }}>
        {target}
        {isOpen ? (
          <Menu onClose={onClose} isMulti={isMulti} size={size}>
            {children}
          </Menu>
        ) : null}
      </div>
    );
  }
);
