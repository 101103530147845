import cn from 'classnames';
import { FC } from 'react';
import { Button } from '../../../Button';

interface IProps {
  title: string;
  subTitle: string;
  buttonLabel: string;
  callback: () => void;
  className?: string;
}
export const GreySection: FC<IProps> = ({ title, subTitle, buttonLabel, callback, className }) => (
  <div
    className={cn(
      'flex items-center justify-between p-6 bg-[#F8F8F8] border border-[#F0F0F0] rounded-[10px] mb-[102px]',
      className
    )}
  >
    <div className='w-4/6 text-left'>
      <h3 className='text-lg/4.5 font-medium mb-2.5'>{title}</h3>
      <p className='text-sm/4 text-black/60'>{subTitle}</p>
    </div>
    <div className='w-[104px]'>
      <Button variant='outlined' className='!text-base' type='button' onClick={callback} label={buttonLabel} />
    </div>
  </div>
);
