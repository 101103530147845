import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import cn from 'classnames';
import { getRandomUniqueArray } from './utils';

interface RangeSliderProps {
  containerClassName?: string;
  label?: string;
  min: number;
  max: number;
  defaultValue: number;
  step?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
  showSeparators?: boolean;
  marks?: {
    [key: string]: string | number;
  };
  minValuePrefix?: string;
  minValueSuffix?: string;
  maxValuePrefix?: string;
  maxValueSuffix?: string;
  tooltipValuePrefix?: string;
  tooltipValueSuffix?: string;
  tooltipFormatter?: (value: number) => string;
}

export const RangeSlider: FC<RangeSliderProps> = ({
  containerClassName,
  min,
  max,
  defaultValue,
  step = 1,
  onChange,
  disabled = false,
  showSeparators = false,
  marks = null,
  minValuePrefix = '',
  minValueSuffix = '',
  maxValuePrefix = '',
  maxValueSuffix = '',
  tooltipValuePrefix = '',
  tooltipValueSuffix = '',
  tooltipFormatter,
  label,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [showTooltip, setShowTooltip] = useState(false);
  const [sliderHovered, setSliderHovered] = useState(false);
  const [sliderLeft, setSliderLeft] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);
  const draggingRef = useRef<boolean>(false);

  useEffect(() => {
    const fullWidth = sliderRef.current?.clientWidth ?? 0;
    const left = ((value - min) / (max - min)) * 100;
    const thumbWidth = 24;
    const offset = (thumbWidth / fullWidth) * 100;
    const result = left - offset / 2;

    const thumbWidthInPercentageByBlock = (thumbWidth / fullWidth) * 100;

    if (result < 0) {
      setSliderLeft(0);
    } else if (left > 99) {
      setSliderLeft(100 - thumbWidthInPercentageByBlock);
    } else {
      setSliderLeft(result);
    }
  }, [value, min, max]);

  const handleMouseDown = () => {
    draggingRef.current = true;
    setShowTooltip(() => true);
  };

  const handleMouseUp = useCallback(() => {
    draggingRef.current = false;
    if (!sliderHovered) {
      setShowTooltip(() => false);
    }
  }, [sliderHovered]);

  const handleSliderEvent = useCallback(
    (e: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
      if (disabled) {
        return;
      }

      if (draggingRef.current) {
        const rect = sliderRef.current?.getBoundingClientRect();
        if (rect) {
          const percent = (e.clientX - rect.left) / rect.width;
          const newValue = min + (max - min) * percent;
          if (newValue >= min && newValue <= max) {
            setValue(roundToStep(newValue, step));
            if (onChange) {
              onChange(roundToStep(newValue, step));
            }
          }
        }
      }
    },
    [disabled, max, min, step, onChange]
  );

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    handleSliderEvent(e);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      return;
    }

    const rect = sliderRef.current?.getBoundingClientRect();
    if (rect) {
      const percent = (e.clientX - rect.left) / rect.width;
      const calculatedValue = min + (max - min) * percent;

      if (calculatedValue >= min && calculatedValue <= max) {
        setValue(roundToStep(calculatedValue, step));
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setValue(roundToStep(newValue, step));
  };

  const roundToStep = (value: number, step: number) => {
    const decimals = step.toString().split('.')[1]?.length || 0;
    const roundedValue = Number((Math.round(value / step) * step).toFixed(decimals));
    return roundedValue;
  };

  useEffect(() => {
    const handleDocumentMouseMove = (e: MouseEvent) => {
      handleSliderEvent(e);
    };

    const handleDocumentMouseUp = () => {
      handleMouseUp();
    };

    document.addEventListener('mousemove', handleDocumentMouseMove);
    document.addEventListener('mouseup', handleDocumentMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleDocumentMouseMove);
      document.removeEventListener('mouseup', handleDocumentMouseUp);
    };
  }, [min, max, step, handleSliderEvent, handleMouseUp]);

  return (
    <div className={containerClassName}>
      {label && <div className='text-sm/4 opacity-[0.699999988079071] pb-[10px]'>{label}</div>}
      <div
        className='flex items-center h-6'
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
        ref={sliderRef}
      >
        <div className='relative h-[10px] w-full bg-[#EEEEEE] rounded-[100px]'>
          {showSeparators &&
            getRandomUniqueArray((max - 2) / step).map((item, index) => {
              const oneOffset = 100 / ((max - 1) / step);
              const mark = (marks && marks[index + 2]) || null;

              return (
                <div
                  key={item.toString()}
                  className={`absolute h-[10px] border-l border-ui-gray-200 z-[1]`}
                  style={{ left: `${(index + 1) * oneOffset}%` }}
                >
                  {mark && (
                    <div
                      className={twMerge(
                        cn(
                          'absolute top-[calc(100%+17px)] left-[50%] translate-x-[-50%] flex whitespace-nowrap text-base font-medium',
                          {
                            'text-ui-gray-200': value !== index + 2,
                          }
                        )
                      )}
                    >
                      {mark}
                    </div>
                  )}
                </div>
              );
            })}
          <div
            className={twMerge(
              cn('relative h-[10px] bg-ui-primary-400 rounded-[100px] z-[2] transition-all', {
                'bg-ui-gray-400': disabled,
              })
            )}
            style={{ width: ((value - min) / (max - min)) * 100 + '%' }}
          />
          <button
            className={twMerge(
              cn(
                'absolute border-[5px] border-ui-primary-400 rounded-full w-6 h-6 bg-ui-white top-[50%] translate-y-[-50%] cursor-pointer z-[3] transition-all',
                {
                  'border-ui-gray-400': disabled,
                }
              )
            )}
            style={{ left: `${sliderLeft}%` }}
            onMouseEnter={() => {
              setSliderHovered(true);
              setShowTooltip(true);
            }}
            onMouseLeave={() => {
              setSliderHovered(false);
              !draggingRef.current && setShowTooltip(false);
            }}
          >
            <div
              className={twMerge(
                cn(
                  'absolute px-2 py-1 rounded-ful bg-ui-white left-8 top-[50%] translate-y-[-50%] text-base font-medium text-ui-gray-700 rounded-[3px] select-none pointer-events-none whitespace-nowrap drop-shadow-[-1px_1px_20px_rgba(16,16,16,0.12)]',
                  {
                    hidden: false, // hardcoded so we always have the tooltip
                    // hidden: !showTooltip,
                    block: showTooltip,
                  }
                )
              )}
            >
              {tooltipFormatter ? tooltipFormatter(value) : `${tooltipValuePrefix}${value}${tooltipValueSuffix}`}
              <svg
                className='absolute left-[-5px] top-[50%] translate-y-[-50%] z-[1]'
                width='5.964'
                height='8'
                viewBox='20.2062 10.2399 5.7938 9.7601'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M20.4364 14.622C20.2062 14.8214 20.2062 15.1786 20.4364 15.378L25.1727 19.4797C25.4965 19.7601 26 19.5301 26 19.1017L26 10.8983C26 10.4699 25.4965 10.2399 25.1727 10.5203L20.4364 14.622Z'
                  fill='white'
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
      <div className='flex justify-between text-base font-medium pt-[10px] text-ui-gray-700'>
        <span>
          {minValuePrefix}
          {min}
          {minValueSuffix}
        </span>
        <span>
          {maxValuePrefix}
          {max}
          {maxValueSuffix}
        </span>
      </div>
      <input type='range' className='hidden' min={min} max={max} value={value} step={step} onChange={handleChange} />
    </div>
  );
};
